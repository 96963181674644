import { filter, mergeMap } from "rxjs/operators";

import { RootEpic } from "../store/root-epic";
import {
  exportResults,
  exportResultsFailure,
  exportResultsSuccess,
} from "../reducers/share.reducer";
import { exportResultsRequestSelector } from "../selectors/export-results-request.selector";

const exportResultsEpic: RootEpic = (actions$, state$, { api, download }) => {
  return actions$.pipe(
    filter(exportResults.match),
    mergeMap(() => {
      const request = exportResultsRequestSelector(state$.value);
      return api.share
        .createFileForDownload(request)
        .then((response) => {
          download.downloadFile(response.uri);
          return [exportResultsSuccess()];
        })
        .catch((error) => [exportResultsFailure({ error })]);
    }),
    mergeMap((action) => action)
  );
};

export { exportResultsEpic };
