import { Components, Theme } from "@mui/material";

const MuiFab: Components<Theme>["MuiFab"] = {
  styleOverrides: {
    circular: {
      boxShadow: "none",
    },
  },
};

export { MuiFab };
