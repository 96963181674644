import { Components } from "@mui/material";

import { COLOR_GREY_DARK, COLOR_GREY_MEDIUM } from "../colors";

const MuiCheckbox: Components["MuiCheckbox"] = {
  styleOverrides: {
    root: {
      color: COLOR_GREY_DARK,
      "&.Mui-disabled": {
        color: COLOR_GREY_MEDIUM,
      },
    },
  },
};

export { MuiCheckbox };
