const COLOR_BLACK = "#262626";
const COLOR_WHITE = "#FFFFFF";

const COLOR_GREY_DARK = "#9E9FA0";
const COLOR_GREY_MEDIUM = "#CCCCCC";
const COLOR_GREY_LIGHT = "#F2F2F2";

const COLOR_BLUE_PRIMARY = "#3760EF";
const COLOR_BLUE_LIGHT = "#E6ECFF";
const COLOR_BLUE_FADED = "#E2E9FF";

const COLOR_YELLOW_SECONDARY = "#FFD700";
const COLOR_YELLOW_LIGHT = "#FFF09D";

const COLOR_FLAG_BLUE = COLOR_BLUE_PRIMARY;
const COLOR_FLAG_YELLOW = COLOR_YELLOW_SECONDARY;

export {
  COLOR_BLACK,
  COLOR_WHITE,
  COLOR_GREY_MEDIUM,
  COLOR_GREY_DARK,
  COLOR_GREY_LIGHT,
  COLOR_BLUE_PRIMARY,
  COLOR_BLUE_LIGHT,
  COLOR_BLUE_FADED,
  COLOR_YELLOW_SECONDARY,
  COLOR_YELLOW_LIGHT,
  COLOR_FLAG_BLUE,
  COLOR_FLAG_YELLOW,
};
