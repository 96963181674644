import React, { useCallback, useState } from "react";
import {
  ThumbDown as ThumbDownIcon,
  ThumbDownOutlined as ThumbDownOutlinedIcon,
  ThumbUp as ThumbUpIcon,
  ThumbUpOutlined as ThumbUpOutlinedIcon,
  DeleteForever as DeleteForeverIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  setDislike,
  setLike,
  setUndislike,
  setUnlike,
} from "../../reducers/favorites.reducer";
import { changeCountryPick } from "../../reducers/pick.reducer";
import { clearSelected } from "../../reducers/selected.reducer";
import { logClickEvent } from "../../utils/gtag";

type Props = {
  countryCode: string;
  isLiked: boolean;
  isDisliked: boolean;
};

function TableToolbar({ countryCode, isLiked, isDisliked }: Props) {
  const dispatch = useDispatch();
  const [isConfirmRemoveOpen, setConfirmRemoveOpen] = useState(false);

  const updateLike = useCallback(() => {
    logClickEvent({
      event_category: "like",
      event_label: countryCode,
      value: isLiked ? "off" : "on",
    });
    if (isLiked) {
      dispatch(setUnlike(countryCode));
    } else {
      dispatch(setLike(countryCode));
    }
  }, [countryCode, dispatch, isLiked]);

  const updateDislike = useCallback(() => {
    logClickEvent({
      event_category: "dislike",
      event_label: countryCode,
      value: isDisliked ? "off" : "on",
    });
    if (isDisliked) {
      dispatch(setUndislike(countryCode));
    } else {
      dispatch(setDislike(countryCode));
    }
  }, [countryCode, dispatch, isDisliked]);

  const removeCountry = useCallback(() => {
    dispatch(changeCountryPick({ countries: [countryCode], selected: false }));
    setConfirmRemoveOpen(false);
  }, [countryCode, dispatch]);

  return (
    <>
      <Stack direction="row">
        <IconButton aria-label="Like" onClick={updateLike}>
          {isLiked ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
        </IconButton>
        <IconButton aria-label="Dislike" onClick={updateDislike}>
          {isDisliked ? <ThumbDownIcon /> : <ThumbDownOutlinedIcon />}
        </IconButton>
        <IconButton
          aria-label="Remove"
          onClick={() => setConfirmRemoveOpen(true)}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <Dialog
        open={isConfirmRemoveOpen}
        onClose={() => dispatch(clearSelected())}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          <DeleteForeverIcon color="error" />
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle3">Удалить из списка?</Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={() => setConfirmRemoveOpen(false)}
            variant="text"
            size="small"
            color="info"
          >
            Отмена
          </Button>
          <Button onClick={removeCountry} variant="outlined" size="small">
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TableToolbar;
