import React from "react";
import { Link, Typography } from "@mui/material";
import { typography } from "../../theme/typography";

const ANCHOR_REGEX = /(https?:\/\/[^\s]+)/g;

type Props = {
  text: string;
};

function TextWithLinks({ text }: Props) {
  const textParts = text.split(ANCHOR_REGEX);

  return (
    <Typography
      variant="body4"
      component="pre"
      whiteSpace="break-spaces"
      fontFamily={typography.fontFamily}
    >
      {textParts.map((part, index) =>
        ANCHOR_REGEX.test(part) ? (
          <Link
            href={part}
            variant="body4"
            target="_blank"
            rel="noopener noreferrer"
            style={{ wordWrap: "break-word" }}
            key={index}
          >
            {part}
          </Link>
        ) : (
          <span key={index}>{part}</span>
        )
      )}
    </Typography>
  );
}

export default TextWithLinks;
