import React from "react";
import { Button, Box, Typography, Grid } from "@mui/material";

import { Link } from "react-router-dom";
import { VarenikSad } from "../../images";

function TableEmpty() {
  return (
    <Box mb={7} pr={1} pl={1}>
      <Grid container>
        <Grid item xs={12} md={4}>
          <VarenikSad width="100%" />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h1" mb={2}>
            Кажется, здесь нет ничего...
          </Typography>
          <Typography variant="subtitle1">
            Чтобы увидеть результаты поиска, нужно выбрать хотя бы одну страну
            для сравнения и минимум один параметр.
          </Typography>
          <Typography variant="subtitle1" mb={7}>
            Вернись назад на страницу поиска, чтобы получить результат.
          </Typography>
          <Button
            component={Link}
            to="/"
            variant="outlined"
            color="primary"
            size="large"
          >
            На главную страницу
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default TableEmpty;
