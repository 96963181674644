import React, { useEffect, useState } from "react";
import { Box, Fab, Typography } from "@mui/material";

import { ArrowUpward as ArrowUpwardIcon } from "@mui/icons-material";

import { theme } from "../../theme";

function TableScrollTop() {
  const [isScrollTopVisible, setScrollTopVisible] = useState(false);
  useEffect(() => {
    function Sticky() {
      const scrollTop = window.scrollY;
      setScrollTopVisible(scrollTop >= 250);
    }
    window.addEventListener("scroll", Sticky);
    return () => {
      window.removeEventListener("scroll", Sticky);
    };
  }, []);

  if (!isScrollTopVisible) {
    return null;
  }

  return (
    <Box position="fixed" right={theme.spacing(1)} bottom={theme.spacing(7)}>
      <Fab color="primary" onClick={() => window.scrollTo(0, 0)}>
        <ArrowUpwardIcon />
      </Fab>
      <Typography variant="body3" color="primary" display="block">
        наверх
      </Typography>
    </Box>
  );
}

export default TableScrollTop;
