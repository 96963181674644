import React, { useCallback, useMemo } from "react";
import { Grid } from "@mui/material";

import { ChipCheckbox } from "../../inputs/chip-checkbox";
import { Criterion } from "../../domain";

type Props = {
  criteria: Criterion[];
  selected: Criterion["code"][];
  onChange: (criterion: Criterion["code"], checked: boolean) => void;
};

const Criteria: React.FC<Props> = ({ criteria, selected, onChange }: Props) => {
  const checked = useMemo(() => new Set(selected), [selected]);

  const handleClick: (value: unknown) => void = useCallback(
    (value) => {
      const code = value as Criterion["code"];
      onChange(code, !checked.has(code));
    },
    [checked, onChange]
  );

  return (
    <Grid container spacing={2}>
      {criteria.map((criterion) => (
        <Grid item key={criterion.code}>
          <ChipCheckbox
            checked={checked.has(criterion.code)}
            label={criterion.name}
            onClick={handleClick}
            value={criterion.code}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export { Criteria };
