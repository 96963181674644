import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ArrowBackIos as BackIcon } from "@mui/icons-material";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { ShareCard } from "../components/share-card";
import {
  ClipboardIcon,
  VarenikFileImage,
  VarenikMailGreyImage,
  VarenikMailImage,
  VarenikShareImage,
} from "../images";
import { exportResults } from "../reducers/share.reducer";
import { querySelector } from "../selectors/query.selector";
import { ProgressButton } from "../inputs/progress-button";
import { RootState } from "../store/root-reducer";
import { useFlashSuccess } from "../hooks";
import { SectionHeader } from "../components/section-header";
import { logClickEvent, logPageViewEvent } from "../utils/gtag";

const selector = (state: RootState) => ({
  query: querySelector(state),
  exportingExcel: state.share.exportingExcel,
  emailingExcel: state.share.emailingExcel,
});

const SharePage: React.FC = () => {
  const dispatch = useDispatch();
  const [copied, setCopied] = useState<boolean | "success">(false);
  const [enteringEmail] = useState(false);
  const { query, exportingExcel, emailingExcel } = useSelector(selector);
  const shareLink = window.location.origin + "/results?" + query;

  const flashExportExcel = useFlashSuccess(exportingExcel);
  const flashEmailExcel = useFlashSuccess(emailingExcel);
  const flashCopied = useFlashSuccess(copied);

  useEffect(() => {
    logPageViewEvent({
      page_title: "Varenik.help - Share",
    });
    window.scrollTo(0, 0);
  }, []);

  const handleExportExcel = useCallback(() => {
    logClickEvent({
      event_category: "share",
      event_label: "download",
      value: query,
    });
    dispatch(exportResults());
  }, [dispatch, query]);
  const handleCopy = useCallback(() => {
    logClickEvent({
      event_category: "share",
      event_label: "copy",
      value: query,
    });
    setCopied(true);
    setTimeout(() => setCopied("success"));
  }, [query]);

  const emailDisabled = true;

  return (
    <Container>
      {/* Back */}
      <Box display="flex">
        <Link
          variant="subtitle2"
          textTransform="uppercase"
          color="primary"
          underline="none"
          component={RouterLink}
          to="/results"
          display="flex"
          alignItems="center"
          justifySelf="start"
        >
          <BackIcon />
          Назад
        </Link>
      </Box>

      {/* Share Cards */}
      <Box
        mt={2}
        display="grid"
        gridTemplateColumns={{ md: "repeat(3, 1fr)" }}
        gap={4}
      >
        {/* Download */}
        <Box display="flex">
          <ShareCard
            image={<VarenikFileImage />}
            title="Сохранить файл"
            description={
              "Тебе или другу нужен доступ к таблице в любое время и с любого устройства? " +
              "Отправь ее на любое количество электронных адресов!"
            }
            success={flashExportExcel ? "Файл успешно сохранен" : undefined}
          >
            <Stack spacing={2}>
              <ProgressButton
                variant="outlined"
                size="medium"
                onClick={handleExportExcel}
                progress={exportingExcel === true}
                sx={{ alignSelf: "center" }}
              >
                Скачать в .XLSX
              </ProgressButton>
            </Stack>
          </ShareCard>
        </Box>

        {/* Copy to clipboard */}
        <Box display="flex">
          <ShareCard
            image={<VarenikShareImage />}
            title="Поделиться ссылкой"
            description={
              "Поделись своим результатом с другими - отправь ссылку. " +
              "Они смогут увидеть, что выбрал ты и добавить или убрать любые параметры и страны!"
            }
            success={
              flashCopied ? "Cсылка скопирована в буфер обмена" : undefined
            }
          >
            <Stack spacing={2}>
              <CopyToClipboard text={shareLink} onCopy={handleCopy}>
                <Button
                  variant="outlined"
                  size="medium"
                  startIcon={<ClipboardIcon />}
                  sx={{ alignSelf: "center" }}
                >
                  Скопировать
                </Button>
              </CopyToClipboard>
            </Stack>
          </ShareCard>
        </Box>

        {/* Email */}
        <Box display="flex">
          <ShareCard
            image={
              emailDisabled ? <VarenikMailGreyImage /> : <VarenikMailImage />
            }
            title="Отправить на почту"
            description={
              "Тебе или другу нужен доступ к таблице в любое время и с любого устройства? " +
              "Отправь ее на любое количество электронных адресов!"
            }
            success={flashEmailExcel ? "Файл успешно отправлен" : undefined}
            disabled={emailDisabled}
          >
            <Stack spacing={2}>
              {enteringEmail && (
                <TextField
                  size="small"
                  fullWidth
                  placeholder="Введи адрес электронной почты"
                  disabled={emailDisabled}
                />
              )}
              <ProgressButton
                variant="outlined"
                size="medium"
                onClick={() => undefined}
                progress={emailingExcel === true}
                sx={{ alignSelf: "center" }}
                disabled={emailDisabled}
              >
                Отправить
              </ProgressButton>
            </Stack>
          </ShareCard>
        </Box>
      </Box>
      {/* Messages for refugees and volunteers */}
      <Box
        mt={4}
        display="grid"
        gridTemplateColumns={{ md: "2fr 1fr" }}
        gap={4}
      >
        <Box>
          <SectionHeader title="Для беженцев" />
          <Typography variant="body5" mt={2}>
            Принять решение о переезде непросто, но если ты готов и тебе нужна
            помочь — волонтеры команды Рубикус готовы тебе помочь. Мы помогаем
            людям выехать из Украины и перебраться в Европу.
          </Typography>
          <Typography variant="body5" mt={1}>
            Мы подберём подходящий транспорт, временное жилье и сделаем всё, что
            в наших силах, чтобы помочь на всех этапах переезда! На нашем сайте
            ты можешь оставить заявку и наши координаторы свяжутся с тобой в
            течение 48 часов.
          </Typography>

          <Box mt={2} display="flex" justifyItems="start">
            <Paper
              sx={{
                backgroundColor: "flag.yellow",
                textAlign: "center",
                px: 4,
                py: 2,
              }}
            >
              <Link
                href="https://helpua.rubikus.de/"
                target="_blank"
                rel="noopener noreferrer"
                variant="h3"
                underline="none"
              >
                Перейти на сайт Rubikus.helpUA
              </Link>
            </Paper>
          </Box>
        </Box>
        <Box>
          <SectionHeader title="Для волонтеров" />
          <Typography variant="body5" mt={2}>
            Если ты коллега-волонтер и нашел неточности или ошибки - обязательно
            напиши нам. Нашедшим не менее пяти — почет, слава и реклама вашего
            ресурса!
          </Typography>
          <Link
            mt={2}
            display="block"
            href="https://forms.gle/V5No5avuApkqH9ou5"
            target="_blank"
            rel="noopener noreferrer"
            variant="subtitle1"
          >
            Оставить отзыв
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export { SharePage };
