import React from "react";
import { Button, Box, styled } from "@mui/material";

import { Link } from "react-router-dom";
import TableScrollTop from "../table-scroll-top/TableScrollTop";

const ActionsBox = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(7),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  textAlign: "center",
}));

function TableFooter() {
  return (
    <ActionsBox>
      <Button
        component={Link}
        to="/share"
        variant="contained"
        color="secondary"
        size="large"
      >
        Сохранить Результаты
      </Button>
      <TableScrollTop />
    </ActionsBox>
  );
}

export default TableFooter;
