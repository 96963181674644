import { combineEpics, Epic } from "redux-observable";
import { Action } from "@reduxjs/toolkit";
import { catchError } from "rxjs/operators";

import { loadDataEpic, exportResultsEpic, emailResultsEpic } from "../epics";
import { Services } from "../api";
import { RootState } from "./root-reducer";

export type RootEpic = Epic<Action, Action, RootState, Services>;

const combinedEpic: RootEpic = combineEpics(
  loadDataEpic,
  exportResultsEpic,
  emailResultsEpic
);

const rootEpic: RootEpic = (action$, state$, deps) =>
  combinedEpic(action$, state$, deps).pipe(
    catchError((error, source) => {
      console.error(error);
      return source;
    })
  );

export { rootEpic };
