import { createAction, createReducer } from "@reduxjs/toolkit";
import { EmailResultsRequest } from "../domain";

const initialState = {
  exportingExcel: false as boolean | "success",
  emailingExcel: false as boolean | "success",
  error: null as Error | null,
};

const NAMESPACE = "share";

const exportResults = createAction(`${NAMESPACE}/exportResults`);
const exportResultsSuccess = createAction(`${NAMESPACE}/exportResultsSuccess`);
const exportResultsFailure = createAction<{ error: Error }>(
  `${NAMESPACE}/exportResultsFailure`
);

const emailResults = createAction<{ email: EmailResultsRequest["email"] }>(
  `${NAMESPACE}/emailResults`
);
const emailResultsSuccess = createAction(`${NAMESPACE}/emailResultsSuccess`);
const emailResultsFailure = createAction<{ error: Error }>(
  `${NAMESPACE}/emailResultsFailure`
);

const shareReducer = createReducer(initialState, (builder) => {
  return builder
    .addCase(exportResults, (state) => {
      const updated: typeof state = {
        ...state,
        exportingExcel: true,
      };

      return updated;
    })
    .addCase(exportResultsSuccess, (state) => {
      const updated: typeof state = {
        ...state,
        exportingExcel: "success",
      };

      return updated;
    })
    .addCase(exportResultsFailure, (state, { payload: { error } }) => {
      const updated: typeof state = {
        ...state,
        exportingExcel: false,
        error,
      };

      return updated;
    })
    .addCase(emailResults, (state) => {
      const updated: typeof state = {
        ...state,
        emailingExcel: true,
      };

      return updated;
    })
    .addCase(emailResultsSuccess, (state) => {
      const updated: typeof state = {
        ...state,
        emailingExcel: "success",
      };

      return updated;
    })
    .addCase(emailResultsFailure, (state, { payload: { error } }) => {
      const updated: typeof state = {
        ...state,
        emailingExcel: false,
        error,
      };

      return updated;
    });
});

export {
  shareReducer,
  exportResults,
  exportResultsSuccess,
  exportResultsFailure,
  emailResults,
  emailResultsSuccess,
  emailResultsFailure,
};
