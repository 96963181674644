import { createTheme } from "@mui/material";
import { Shadows } from "@mui/material/styles/shadows";

const theme = createTheme();

const shadows = [...theme.shadows] as Shadows;

shadows[1] = "0px 2px 4px rgba(153, 146, 136, 0.15)";
shadows[2] = "0px 8px 19px rgba(153, 146, 136, 0.22)";

export { shadows };
