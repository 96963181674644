import { createSelector } from "reselect";

import { RootState } from "../store/root-reducer";
import { Criterion } from "../domain";
import { codeColumns, rows } from "../config/data.config";

const shortDataSelector = (state: RootState) => state.data.short;

function makeCriterion({
  code,
  name,
}: {
  code: Criterion["code"];
  name: Criterion["name"];
}): Criterion {
  return {
    code,
    name,
  };
}

const criteriaSelector: (state: RootState) => Criterion[] = createSelector(
  [shortDataSelector],
  (data) => {
    if (data == null) {
      return [];
    }

    const hiddenCriteriaCodeIndexes = new Set([
      codeColumns.countryName,
      codeColumns.countryCode,
      codeColumns.acceptsRefugees,
      codeColumns.isEurope,
    ]);

    const headersRow = data[rows.headerRow] as string[];
    const codesRow = data[rows.columnCodeRow] as string[];
    return codesRow.reduce((result, code, index) => {
      if (hiddenCriteriaCodeIndexes.has(index) || code === "") {
        return result;
      }

      return [...result, makeCriterion({ code, name: headersRow[index] })];
    }, [] as Criterion[]);
  }
);

export { criteriaSelector };
