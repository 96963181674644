import { createAction, createReducer } from "@reduxjs/toolkit";

interface FavoritesState {
  liked: string[];
  disliked: string[];
}

const NAMESPACE = "favorites";

const clear = createAction(`${NAMESPACE}/clear`);
const setLike = createAction<string>(`${NAMESPACE}/setLike`);
const setUnlike = createAction<string>(`${NAMESPACE}/setUnlike`);
const setDislike = createAction<string>(`${NAMESPACE}/setDislike`);
const setUndislike = createAction<string>(`${NAMESPACE}/setUndislike`);
const setFavorites = createAction<FavoritesState>(`${NAMESPACE}/setFavorites`);

const favoritesReducer = createReducer<FavoritesState>(
  { liked: [], disliked: [] },
  (builder) => {
    builder
      .addCase(clear, (state, action) => {
        return { liked: [], disliked: [] };
      })
      .addCase(setLike, (state, action) => {
        return {
          ...state,
          liked: [...state.liked, action.payload],
          disliked: state.disliked.filter(
            (country) => country !== action.payload
          ),
        };
      })
      .addCase(setUnlike, (state, action) => {
        return {
          ...state,
          liked: state.liked.filter((country) => country !== action.payload),
        };
      })
      .addCase(setDislike, (state, action) => {
        return {
          ...state,
          liked: state.liked.filter((country) => country !== action.payload),
          disliked: [...state.disliked, action.payload],
        };
      })
      .addCase(setUndislike, (state, action) => {
        return {
          ...state,
          disliked: state.disliked.filter(
            (country) => country !== action.payload
          ),
        };
      })
      .addCase(setFavorites, (state, action) => {
        return {
          ...state,
          liked: action.payload.liked,
          disliked: action.payload.disliked,
        };
      });
  }
);

export {
  favoritesReducer,
  clear,
  setLike,
  setUnlike,
  setDislike,
  setUndislike,
  setFavorites,
};
