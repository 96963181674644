import { Components } from "@mui/material";

const MuiTypography: Components["MuiTypography"] = {
  defaultProps: {
    variantMapping: {
      body3: "p",
      body4: "p",
      body5: "p",
    },
  },
};

export { MuiTypography };
