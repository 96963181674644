import { Action, configureStore } from "@reduxjs/toolkit";
import { createEpicMiddleware } from "redux-observable";

import { services, Services } from "../api";
import { rootReducer, RootState } from "./root-reducer";
import { rootEpic } from "./root-epic";

const epicMiddleware = createEpicMiddleware<
  Action,
  Action,
  RootState,
  Services
>({ dependencies: services });

const store = configureStore({
  reducer: rootReducer,
  middleware: [epicMiddleware],
});

epicMiddleware.run(rootEpic);

export type AppDispatch = typeof store.dispatch;

export { store };
