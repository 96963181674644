import { ThemeOptions } from "@mui/material";

import {
  COLOR_BLACK,
  COLOR_BLUE_LIGHT,
  COLOR_FLAG_BLUE,
  COLOR_FLAG_YELLOW,
  COLOR_GREY_MEDIUM,
  COLOR_WHITE,
} from "./colors";

declare module "@mui/material/styles" {
  interface TypeText {
    white: string;
  }

  interface PaletteOptions {
    flag?: {
      blue: string;
      yellow: string;
    };
  }

  export interface Palette {
    flag: {
      blue: string;
      yellow: string;
    };
  }
}

const palette: ThemeOptions["palette"] = {
  text: {
    primary: COLOR_BLACK,
    disabled: COLOR_GREY_MEDIUM,
    white: COLOR_WHITE,
  },
  background: {
    default: COLOR_WHITE,
  },
  primary: {
    main: COLOR_FLAG_BLUE,
    light: COLOR_BLUE_LIGHT,
  },
  secondary: {
    main: COLOR_FLAG_YELLOW,
  },
  info: {
    main: COLOR_BLACK,
  },
  flag: {
    blue: COLOR_FLAG_BLUE,
    yellow: COLOR_FLAG_YELLOW,
  },
};

export { palette };
