import { RegionCountries } from "../domain";

type Layout = Record<keyof RegionCountries, { columns: 1 | 2 | 3 | 4 | 5 }>;

function adjust(europeEstimate: number, otherEstimate: number): Layout {
  if (europeEstimate === 0) {
    return {
      europe: { columns: 1 },
      other: { columns: 5 },
    };
  }
  if (otherEstimate === 0) {
    return {
      europe: { columns: 5 },
      other: { columns: 1 },
    };
  }

  return {
    europe: { columns: europeEstimate },
    other: { columns: otherEstimate },
  } as Layout;
}

function suggestRegionLayout(regions: RegionCountries): Layout {
  const europe = regions.europe.countries.length;
  const other = regions.other.countries.length;

  if (europe === other) {
    return {
      europe: { columns: 3 },
      other: { columns: 3 },
    };
  }

  const targetColumns = 6;
  const totalCountries = europe + other;
  const europeEstimate = Math.round(europe / totalCountries) * targetColumns;
  const otherEstimate = Math.round(other / totalCountries) * targetColumns;

  return adjust(europeEstimate, otherEstimate);
}

export { suggestRegionLayout };
