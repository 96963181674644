import React, { useImperativeHandle, useRef, useState } from "react";
import { Box, Stack, styled, Typography } from "@mui/material";
import { COLOR_BLUE_PRIMARY, COLOR_WHITE } from "../../theme/colors";

const HeaderBox = styled(Box)(({ theme }) => ({
  backgroundColor: COLOR_WHITE,
  top: 0,
  zIndex: 1,
  minWidth: "100%",
}));

const HeaderStack = styled(Stack)(({ theme }) => ({
  paddingTop: theme.spacing(1),
}));

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: COLOR_BLUE_PRIMARY,
  padding: theme.spacing(1),
  color: COLOR_WHITE,
  borderRadius: "4px 4px 0px 0px",
  minWidth: 164,
  maxWidth: 240,
  height: theme.spacing(6),
  display: "flex",
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
  ":first-child": {
    paddingLeft: theme.spacing(2.5),
    minWidth: 179,
    maxWidth: 255,
  },
  ":last-child": {
    paddingRight: theme.spacing(2.5),
    minWidth: 179,
    maxWidth: 255,
  },
  ":only-child": {
    minWidth: 194,
    maxWidth: 270,
  },
}));

type Props = {
  items: string[];
  config: boolean[];
};

export type TableHeaderRefType = {
  setOffset: (offsetX: number) => void;
  setSticky: (forceSticky: boolean) => void;
};

const TableHeader = React.forwardRef<TableHeaderRefType, Props>(
  (props, ref) => {
    const { items, config } = props;
    const headerRef = useRef<HTMLDivElement>(null);
    const [isSticky, setIsSticky] = useState(false);
    useImperativeHandle(ref, () => ({
      setOffset: (offsetX: number) => {
        if (headerRef.current?.style) {
          headerRef.current.style.left = `-${offsetX}px`;
        }
      },
      setSticky: (forceSticky: boolean) => {
        setIsSticky(forceSticky);
      },
    }));

    return (
      <HeaderBox position={isSticky ? "fixed" : "static"} ref={headerRef}>
        <HeaderStack spacing={0.5} direction="row">
          {items.map((item, index) => {
            if (!config[index]) {
              return null;
            }
            return (
              <Item flexGrow={1} key={item}>
                <Typography variant="body3" textAlign="center">
                  {item}
                </Typography>
              </Item>
            );
          })}
        </HeaderStack>
      </HeaderBox>
    );
  }
);
TableHeader.displayName = "TableHeader";

export default TableHeader;
