import { createTheme } from "@mui/material";

import { palette } from "./palette";
import { typography } from "./typography";
import { MuiAppBar } from "./components/app-bar";
import { MuiButton } from "./components/button";
import { MuiCheckbox } from "./components/checkbox";
import { MuiChip } from "./components/chip";
import { shadows } from "./shadows";
import { MuiCard } from "./components/card";
import { MuiCardContent } from "./components/card-content";
import { MuiDialog } from "./components/dialog";
import { MuiFab } from "./components/fab";
import { MuiToolbar } from "./components/toolbar";
import { MuiTypography } from "./components/typography";
import { MuiLink } from "./components/link";
import { MuiContainer } from "./components/container";

const theme = createTheme({
  palette,
  typography,
  components: {
    MuiAppBar,
    MuiButton,
    MuiCard,
    MuiCardContent,
    MuiCheckbox,
    MuiChip,
    MuiContainer,
    MuiDialog,
    MuiFab,
    MuiLink,
    MuiToolbar,
    MuiTypography,
  },
  shadows,
  spacing: 10,
});

export { theme };
