import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Box } from "@mui/material";
import { Main } from "./pages/Main";
import Results from "./pages/Results";
import { SharePage } from "./pages/Share";
import { QueryHandler } from "./components/query-handler/QueryHandler";
import { TopBar } from "./components/top-bar";

function App() {
  return (
    <Router>
      <TopBar />
      <Box my={4}>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/results" element={<Results />} />
          <Route path="/share" element={<SharePage />} />
        </Routes>
      </Box>
      <QueryHandler />
    </Router>
  );
}

export default App;
