import { Box } from "@mui/material";
import { ReactComponent as VarenikPrivetSvg } from "./varenik-privet.svg";
import { ReactComponent as VarenikParamsSvg } from "./varenik-params.svg";

export const VarenikPrivetImage = () => (
  <Box maxWidth={479}>
    <VarenikPrivetSvg />
  </Box>
);
export const VarenikParamsImage = () => (
  <Box maxWidth={231}>
    <VarenikParamsSvg />
  </Box>
);

export { ReactComponent as VarenikFileImage } from "./varenik-file.svg";
export { ReactComponent as VarenikMailImage } from "./varenik-mail.svg";
export { ReactComponent as VarenikMailGreyImage } from "./varenik-mail-grey.svg";
export { ReactComponent as VarenikShareImage } from "./varenik-share.svg";
export { ReactComponent as VarenikSad } from "./varenik-sad.svg";
export { ReactComponent as VarenikSuccess } from "./varenik-success.svg";
export { ReactComponent as ExcelIcon } from "./excel.svg";
export { ReactComponent as PdfIcon } from "./pdf.svg";
export { ReactComponent as ClipboardIcon } from "./clipboard.svg";
export { ReactComponent as VarenikLogoImage } from "./varenik-logo.svg";
export { ReactComponent as RubikusLogoImage } from "./rubikus-logo.svg";
