import React, { useEffect, useCallback } from "react";
import { Link, Stack, Button, styled } from "@mui/material";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import {
  ArrowBackIos as BackIcon,
  ArrowForwardIos as ForwardIcon,
} from "@mui/icons-material";

import { loadData } from "../reducers/data.reducer";
import DataTable from "../components/table/Table";
import CellDetails from "../components/cell-details/CellDetails";
import { clear } from "../reducers/favorites.reducer";
import { logPageViewEvent } from "../utils/gtag";

const HeaderActionsStack = styled(Stack)(({ theme }) => ({
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

function Results() {
  const dispatch = useDispatch();
  const clearFavorites = useCallback(() => dispatch(clear()), [dispatch]);

  useEffect(() => {
    logPageViewEvent({
      page_title: "Varenik.help - Results",
    });
  }, []);

  useEffect(() => {
    dispatch(loadData());
    window.scrollTo(0, 0);
  }, [dispatch]);

  return (
    <>
      <HeaderActionsStack direction="row" alignItems="start" flex={1}>
        <Link
          variant="subtitle2"
          textTransform="uppercase"
          color="primary"
          underline="none"
          component={RouterLink}
          to="/"
          display="flex"
          alignItems="center"
          mt={0.5}
          mr={5}
        >
          <BackIcon />
          Назад
        </Link>
        <Stack
          direction="row"
          alignItems="start"
          justifyContent="end"
          flexWrap="wrap-reverse"
          flex={1}
        >
          <Button variant="outlined" size="small" onClick={clearFavorites}>
            Очистить &quot;нравится&quot;
          </Button>
          <Link
            variant="subtitle2"
            textTransform="uppercase"
            color="primary"
            underline="none"
            component={RouterLink}
            to="/share"
            display="flex"
            alignItems="center"
            mt={0.5}
            ml={5}
          >
            Далее
            <ForwardIcon />
          </Link>
        </Stack>
      </HeaderActionsStack>
      <DataTable />
      <CellDetails />
    </>
  );
}

export default Results;
