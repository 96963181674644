import { Components, Theme } from "@mui/material";

const MuiCardContent: Components<Theme>["MuiCardContent"] = {
  styleOverrides: {
    root: ({ theme: { spacing } }) => ({
      padding: spacing(1, 3),
      "&:first-child": {
        paddingTop: spacing(2),
      },
      "&:last-child": {
        paddingBottom: spacing(2),
      },
    }),
  },
};

export { MuiCardContent };
