import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setFavorites } from "../../reducers/favorites.reducer";
import {
  changeCountryPick,
  changeCriterionPick,
} from "../../reducers/pick.reducer";
import { querySelector } from "../../selectors/query.selector";

const QueryHandler = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const query = useSelector(querySelector);

  useEffect(
    () => setSearchParams(query, { replace: true }),
    [query, setSearchParams]
  );

  useEffect(() => {
    const separator = "|";
    const criteria = searchParams.get("criteria")?.split(separator) ?? [];
    const countries = searchParams.get("countries")?.split(separator) ?? [];
    const liked = searchParams.get("liked")?.split(separator) ?? [];
    const disliked = searchParams.get("disliked")?.split(separator) ?? [];

    dispatch(changeCriterionPick({ criteria: criteria, selected: true }));
    dispatch(changeCountryPick({ countries: countries, selected: true }));
    // we have to update all favorites at once. Otherwise we will have to introduce
    // a mechanism to wait for dispatch finishing the state update
    dispatch(setFavorites({ liked, disliked }));
    // we need to update state from URL only once - on page load
    // eslint-disable-next-line
  }, []);

  return <></>;
};

export { QueryHandler };
