import { filter, mergeMap } from "rxjs/operators";

import { RootEpic } from "../store/root-epic";
import { loadData, setData } from "../reducers/data.reducer";

const loadDataEpic: RootEpic = (actions$, state$, { api }) => {
  return actions$.pipe(
    filter(loadData.match),
    mergeMap(() => {
      return api.data.fetchData().then((data) =>
        setData({
          updateDate: data.updateData,
          short: data.dataShort,
          full: data.dataLong,
        })
      );
    })
  );
};

export { loadDataEpic };
