import { createSelector } from "reselect";
import { RootState } from "../store/root-reducer";
import {
  pickedCountriesSelector,
  pickedCriteriaSelector,
} from "./pick.selector";
import { favoritesSelector } from "./favorites.selector";

const querySelector: (state: RootState) => string = createSelector(
  [pickedCountriesSelector, pickedCriteriaSelector, favoritesSelector],
  (countries, criteria, favorites) => {
    const separator = "|";
    const queryObject = {
      countries: countries.join(separator),
      criteria: criteria.join(separator),
      liked: favorites.liked.join(separator),
      disliked: favorites.disliked.join(separator),
    };
    return new URLSearchParams(
      Object.entries(queryObject).filter(([, v]) => v !== "")
    ).toString();
  }
);

export { querySelector };
