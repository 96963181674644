const columns = {
  countryName: "countryName",
} as const;

const codeColumns = {
  countryName: 0,
  countryCode: 1,
  acceptsRefugees: 2,
  isEurope: 3,
};

const rows = {
  headerRow: 0,
  columnCodeRow: 1,
  firstDataRow: 2,
} as const;

const cells = {
  europeOtherSeparator: "",
} as const;

export { columns, rows, cells, codeColumns };
