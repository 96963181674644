const downloadFile = (url: string, fileName?: string): void => {
  const link = document.createElement("a");
  link.href = url;
  if (fileName) {
    link.setAttribute("download", fileName);
  }
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
};

export { downloadFile };
