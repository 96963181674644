import React from "react";
import { TypographyOptions } from "@mui/material/styles/createTypography";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    body5: React.CSSProperties;
    subtitle3: React.CSSProperties;
    extraSmall: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    body5?: React.CSSProperties;
    subtitle3: React.CSSProperties;
    extraSmall?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
    body5: true;
    subtitle3: true;
    extraSmall: true;
  }
}

const typography: TypographyOptions = {
  fontFamily: "Open Sans, sans-serif",
  h1: {
    fontSize: "32px",
    lineHeight: "44px",
    fontWeight: 600,
  },
  h2: {
    fontSize: "24px",
    lineHeight: "36px",
    fontWeight: 600,
  },
  h3: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "36px",
  },
  body1: {
    fontSize: "20px",
    lineHeight: "27px",
    fontWeight: 400,
  },
  body2: {
    fontSize: "20px",
    lineHeight: "27px",
    fontWeight: 600,
  },
  body3: {
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: 700,
    textTransform: "uppercase",
  },
  body4: {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 400,
  },
  body5: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
  },
  extraSmall: {
    fontSize: "11px",
    lineHeight: "15px",
    fontWeight: 400,
  },
  subtitle1: {
    fontSize: "24px",
    lineHeight: "36px",
    fontWeight: 400,
  },
  subtitle2: {
    fontSize: "20px",
    lineHeight: "30px",
    fontWeight: 400,
  },
  subtitle3: {
    fontSize: "24px",
    lineHeight: "30px",
    fontWeight: 600,
  },
  button: {
    fontSize: "24px",
    lineHeight: "33px",
    fontWeight: 600,
  },
};

export { typography };
