import * as React from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";

import { useEffect, useRef } from "react";
import TableHeader, { TableHeaderRefType } from "../table-header/TableHeader";
import TableContent from "../table-content/TableContent";
import TableFooter from "../table-footer/TableFooter";
import TableEmpty from "../table-empty/TableEmpty";
import { filteredTableDataSelector } from "../../selectors/data.selector";

export default function DataTable() {
  const scrollRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<TableHeaderRefType>(null);
  const data = useSelector(filteredTableDataSelector);

  useEffect(() => {
    function Sticky() {
      const scrollTop = window.scrollY;
      updateSticky(scrollTop >= 250);
      onScroll();
    }
    window.addEventListener("scroll", Sticky);
    return () => {
      window.removeEventListener("scroll", Sticky);
    };
  }, []);

  function updateSticky(value: boolean) {
    if (headerRef.current == null) {
      return;
    }
    headerRef.current.setSticky(value);
  }

  function onScroll() {
    if (scrollRef.current == null || headerRef.current == null) {
      return;
    }
    headerRef.current.setOffset(scrollRef.current.scrollLeft);
  }

  if (data == null) {
    return (
      <Box mt={6} mb={6} textAlign="center">
        Загружается...
      </Box>
    );
  }

  const { header, content, codes, config } = data;

  if (content.length === 0) {
    return <TableEmpty />;
  }

  return (
    <>
      <Box position="relative" display="inline-block" maxWidth="100%">
        <Box
          overflow="auto"
          onScroll={onScroll}
          ref={scrollRef}
          display="inline-block"
          maxWidth="100%"
        >
          <TableHeader items={header} config={config} ref={headerRef} />
          <TableContent items={content} config={config} codes={codes} />
        </Box>
      </Box>
      <TableFooter />
    </>
  );
}
