import { Components } from "@mui/material";

import { COLOR_BLUE_LIGHT } from "../colors";

const MuiChip: Components["MuiChip"] = {
  variants: [
    {
      props: { variant: "outlined", color: "primary" },
      style: {
        borderColor: COLOR_BLUE_LIGHT,
        backgroundColor: COLOR_BLUE_LIGHT,
      },
    },
  ],
};

export { MuiChip };
