import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  Collapse,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { TransitionGroup } from "react-transition-group";
import { OpenInNewOutlined as OpenInNewOutlinedIcon } from "@mui/icons-material";
import {
  COLOR_BLUE_PRIMARY,
  COLOR_YELLOW_SECONDARY,
  COLOR_GREY_LIGHT,
  COLOR_GREY_MEDIUM,
  COLOR_GREY_DARK,
} from "../../theme/colors";
import { codeColumns } from "../../config/data.config";
import { setSelected } from "../../reducers/selected.reducer";
import TableToolbar from "../table-toolbar/TableToolbar";
import { typography } from "../../theme/typography";
import { FilteredTableDataContentType } from "../../selectors/data.selector";
import { logClickEvent } from "../../utils/gtag";

const CardItem = styled(Card)(({ theme }) => ({
  ":hover": {
    boxShadow: theme.shadows[2],
    "& .MuiSvgIcon-root": {
      color: COLOR_BLUE_PRIMARY,
    },
  },
  minHeight: 200,
  minWidth: 164,
  maxWidth: 240,
  display: "flex",
  flex: 1,
  alignItems: "stretch",
}));

const RowBox = styled(Box)(({ theme }) => ({
  backgroundColor: COLOR_GREY_LIGHT,
  padding: theme.spacing(1.5),
  paddingTop: theme.spacing(1),
  borderRadius: 8,
}));

type Props = {
  items: FilteredTableDataContentType[];
  config: boolean[];
  codes: string[];
};

function TableContent({ items, config, codes }: Props) {
  return (
    <Stack alignItems="start">
      <TransitionGroup>
        {items.map((row, index) => (
          <Collapse key={`${row.values[codeColumns.countryCode]}-${index}`}>
            <TableRow item={row} config={config} codes={codes} />
          </Collapse>
        ))}
      </TransitionGroup>
    </Stack>
  );
}

type RowProps = {
  item: FilteredTableDataContentType;
  config: boolean[];
  codes: string[];
};

function TableRow({ item, config, codes }: RowProps) {
  const dispatch = useDispatch();
  const { values, isLiked, isDisliked } = item;
  const countryCode = values[codeColumns.countryCode];
  const countryName = values[codeColumns.countryName];
  const backgroundColor = isLiked
    ? COLOR_YELLOW_SECONDARY
    : isDisliked
    ? COLOR_GREY_MEDIUM
    : COLOR_GREY_LIGHT;
  return (
    <RowBox style={{ backgroundColor }} mt={0.5}>
      <Stack
        direction="row"
        justifyContent="space-between"
        position="absolute"
        left={0}
        right={0}
        pl={1.5}
        pr={1.5}
      >
        <Typography
          variant="subtitle3"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {countryName}
        </Typography>
        <TableToolbar
          countryCode={countryCode}
          isLiked={isLiked}
          isDisliked={isDisliked}
        />
      </Stack>
      <Stack spacing={0.5} direction="row" mt={4}>
        {values.map((itemValue, index) => {
          if (!config[index]) {
            return null;
          }
          const itemData = {
            countryCode: countryCode,
            criteriaCode: codes[index],
          };
          return (
            <CardItem
              key={`${countryName}-${index}`}
              onClick={() => {
                logClickEvent({
                  event_category: "details",
                  event_label: itemData.countryCode,
                  value: itemData.criteriaCode,
                });
                dispatch(setSelected(itemData));
              }}
            >
              <CardActionArea
                sx={{ flexDirection: "column", alignItems: "stretch" }}
              >
                <Stack justifyContent="space-between" height="100%" p={1}>
                  <Typography
                    variant="extraSmall"
                    component="pre"
                    whiteSpace="break-spaces"
                    fontFamily={typography.fontFamily}
                  >
                    {itemValue}
                  </Typography>
                  <TableCellSpacer />
                  <Stack alignItems="flex-end">
                    <OpenInNewOutlinedIcon
                      fontSize="small"
                      sx={{ color: COLOR_GREY_DARK }}
                    />
                  </Stack>
                </Stack>
              </CardActionArea>
            </CardItem>
          );
        })}
      </Stack>
    </RowBox>
  );
}

function TableCellSpacer() {
  return (
    <Box height="1px">
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp;
    </Box>
  );
}

export default TableContent;
