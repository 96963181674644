const DATA_URI = process.env.REACT_APP_DATA_URI;

export async function fetchData() {
  const url = DATA_URI;
  if (url == null) {
    return null;
  }
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw Error(`${response.status} ${response.statusText}`);
    }
    return await response.json();
  } catch (err) {
    return null;
  }
}
