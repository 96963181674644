import React from "react";
import { Box, Button, ButtonProps, CircularProgress } from "@mui/material";

type Props = ButtonProps & { progress: boolean };

const ProgressButton: React.FC<Props> = ({
  disabled,
  progress,
  children,
  ...rest
}: Props) => (
  <Button {...rest} disabled={disabled || progress}>
    {children}
    {progress && (
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: "-12px",
          marginLeft: "-12px",
        }}
      >
        <CircularProgress size={24} />
      </Box>
    )}
  </Button>
);

export { ProgressButton };
