import React, { useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { Close as CloseIcon } from "@mui/icons-material";
import { selectedDataSelector } from "../../selectors/selected.selector";
import { clearSelected } from "../../reducers/selected.reducer";
import TextWithLinks from "../text-with-links/TextWithLinks";
import { logPageViewEvent } from "../../utils/gtag";

function CellDetails() {
  const dispatch = useDispatch();
  const selectedData = useSelector(selectedDataSelector);

  useEffect(() => {
    if (selectedData != null) {
      logPageViewEvent({
        page_title: "Varenik.help - Details",
      });
    }
  }, [selectedData]);

  if (selectedData == null) {
    return null;
  }

  return (
    <Dialog
      fullWidth={true}
      open={selectedData !== null}
      onClose={() => dispatch(clearSelected())}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="start"
          justifyContent="space-between"
        >
          <Typography variant="subtitle3" pt={0.5}>
            {selectedData.country}: {selectedData.criteria}
          </Typography>
          <IconButton
            aria-label="Close"
            onClick={() => dispatch(clearSelected())}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <TextWithLinks text={selectedData.value} />
      </DialogContent>
    </Dialog>
  );
}

export default CellDetails;
