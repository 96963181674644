import React from "react";
import { Box, BoxProps, SxProps, Theme, Typography } from "@mui/material";

type Props = Omit<BoxProps, "display"> & {
  title: string;
};

const dividerSx: SxProps<Theme> = ({ spacing }) => ({ height: spacing(1) });

const SectionHeader: React.FC<Props> = ({ title, ...box }: Props) => (
  <Box {...box} display="flex">
    <Box>
      <Typography variant="h1">{title}</Typography>
      <Box bgcolor="primary.main" sx={dividerSx} />
    </Box>
  </Box>
);

export { SectionHeader };
