import { useEffect, useState } from "react";

function useFlashSuccess(progress: boolean | "success"): boolean {
  const [flash, seFlash] = useState(false);

  useEffect(() => {
    if (progress === "success") {
      seFlash(true);
      setTimeout(() => seFlash(false), 3_000);
    }
  }, [progress]);
  return flash;
}

export { useFlashSuccess };
