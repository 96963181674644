import React from "react";
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  Tooltip,
  TypographyProps,
} from "@mui/material";

type Props = {
  label: FormControlLabelProps["label"];
  noWrap?: TypographyProps["noWrap"];
} & CheckboxProps;

const CheckboxField: React.FC<Props> = ({
  label,
  noWrap,
  checked,
  disabled,
  title,
  ...checkbox
}: Props) => {
  if (title) {
    return (
      <Tooltip title={title} enterTouchDelay={50}>
        <span>
          <FormControlLabel
            sx={noWrap ? { maxWidth: "100%" } : undefined}
            disabled={disabled}
            control={<Checkbox checked={checked} {...checkbox} />}
            label={label}
            componentsProps={{
              typography: { noWrap, variant: checked ? "body2" : "body1" },
            }}
          />
        </span>
      </Tooltip>
    );
  }
  return (
    <FormControlLabel
      sx={noWrap ? { maxWidth: "100%" } : undefined}
      disabled={disabled}
      control={<Checkbox checked={checked} {...checkbox} />}
      label={label}
      componentsProps={{
        typography: { noWrap, variant: checked ? "body2" : "body1" },
      }}
    />
  );
};

export { CheckboxField };
