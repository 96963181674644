import {
  EmailResultsRequest,
  ExportResultsRequest,
  ExportResultsResponse,
} from "../domain";

const BASE_URL = `${process.env.REACT_APP_API_URI}/Share`;

const createFileForDownload = (
  request: ExportResultsRequest
): Promise<ExportResultsResponse> => {
  const url = `${BASE_URL}/to-file-for-download`;
  const options: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  };
  return fetch(url, options).then((response) => response.json());
};

const emailResultsFile = (request: EmailResultsRequest): Promise<Response> => {
  const url = `${BASE_URL}/to-email`;
  const options: RequestInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  };
  return fetch(url, options);
};

export { createFileForDownload, emailResultsFile };
