import React from "react";
import {
  AppBar,
  Toolbar,
  Link as MuiLink,
  Stack,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { VarenikLogoImage, RubikusLogoImage } from "../../images";

const TopBar: React.FC = () => {
  return (
    <AppBar position="static" color="secondary">
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Stack flexDirection="row" alignItems="center">
          <VarenikLogoImage />
          <MuiLink
            variant="body2"
            color="inherit"
            underline="none"
            component={RouterLink}
            ml={1.5}
            mr="auto"
            to="/"
          >
            Varenik.help
          </MuiLink>
        </Stack>

        <Stack
          display={{ xs: "none", md: "flex" }}
          flexDirection="row"
          alignItems="center"
        >
          <RubikusLogoImage />
          <Typography
            variant="body2"
            color="inherit"
            marginLeft={1}
            paddingRight={8}
          >
            Rubikus.helpUA
          </Typography>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export { TopBar };
