import { Components } from "@mui/material";

const MuiDialog: Components["MuiDialog"] = {
  defaultProps: {
    PaperProps: {
      sx: { borderRadius: "16px" },
    },
  },
  styleOverrides: {
    root: {
      "& .MuiDialogTitle-root": {
        paddingTop: "32px",
        paddingLeft: "40px",
        paddingRight: "32px",
        paddingBottom: 0,
        marginBottom: "30px",
      },
      "& .MuiDialogContent-root": {
        padding: 0,
        marginLeft: "40px",
        marginRight: "40px",
        marginBottom: "40px",
      },
      "& .MuiDialogActions-root": {
        paddingTop: 0,
        paddingBottom: 0,
        marginBottom: "40px",
      },
    },
  },
};

export { MuiDialog };
