import { filter, mergeMap } from "rxjs/operators";

import { RootEpic } from "../store/root-epic";
import {
  emailResults,
  emailResultsFailure,
  emailResultsSuccess,
} from "../reducers/share.reducer";
import { exportResultsRequestSelector } from "../selectors/export-results-request.selector";

const emailResultsEpic: RootEpic = (actions$, state$, { api }) => {
  return actions$.pipe(
    filter(emailResults.match),
    mergeMap(({ payload: { email } }) => {
      const request = { ...exportResultsRequestSelector(state$.value), email };
      return api.share
        .emailResultsFile(request)
        .then(() => [emailResultsSuccess()])
        .catch((error) => [emailResultsFailure({ error })]);
    }),
    mergeMap((action) => action)
  );
};

export { emailResultsEpic };
