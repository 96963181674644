import * as download from "../utils/download";
import * as data from "./data";
import * as share from "./share";

const services = {
  api: {
    data,
    share,
  },
  download,
};

export type Services = typeof services;
export { services };
