declare global {
  interface Window {
    gtag?: (event: string, event_type: string, data: unknown) => void;
  }
}

type ClickEventData = {
  event_category: string;
  event_label: string;
  value: string;
};

type PageViewEventData = {
  page_title: string;
  page_location?: string;
};

export function logClickEvent(data: ClickEventData) {
  if (window.gtag) {
    window.gtag("event", "click", data);
  }
}

export function logPageViewEvent(data: PageViewEventData) {
  if (window.gtag) {
    window.gtag("event", "page_view", data);
  }
}
