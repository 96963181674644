import { createAction, createReducer } from "@reduxjs/toolkit";

import { Country, Criterion } from "../domain";

const initialState = {
  countries: [] as Country["code"][],
  criteria: [] as Criterion["code"][],
};

const NAMESPACE = "pick";

function add<T>(list: T[], items: T[]): T[] {
  return items.reduce(
    (result, item) => (result.includes(item) ? result : [...result, item]),
    list
  );
}

function remove<T>(list: T[], items: T[]): T[] {
  return list.filter((i) => !items.includes(i));
}

const changeCriterionPick = createAction<{
  criteria: Criterion["code"][];
  selected: boolean;
}>(`${NAMESPACE}/changeCriterionPick`);

const changeCountryPick = createAction<{
  countries: Country["code"][];
  selected: boolean;
}>(`${NAMESPACE}/changeCountryPick`);

const clearCountryPicks = createAction(`${NAMESPACE}/clearCountryPicks`);
const clearCriteriaPicks = createAction(`${NAMESPACE}/clearCriteriaPicks`);

const pickReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      changeCriterionPick,
      (state, { payload: { criteria, selected } }) => {
        const updated: typeof state = {
          ...state,
          criteria: selected
            ? add(state.criteria, criteria)
            : remove(state.criteria, criteria),
        };
        return updated;
      }
    )
    .addCase(clearCountryPicks, (state) => {
      const updated: typeof state = {
        ...state,
        countries: [],
      };
      return updated;
    })
    .addCase(
      changeCountryPick,
      (state, { payload: { countries, selected } }) => {
        const updated: typeof state = {
          ...state,
          countries: selected
            ? add(state.countries, countries)
            : remove(state.countries, countries),
        };
        return updated;
      }
    )
    .addCase(clearCriteriaPicks, (state) => {
      const updated: typeof state = {
        ...state,
        criteria: [],
      };
      return updated;
    });
});

export {
  pickReducer,
  changeCriterionPick,
  changeCountryPick,
  clearCountryPicks,
  clearCriteriaPicks,
};
