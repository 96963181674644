import React, { useCallback, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { CountriesRegion } from "../components/countries-region";
import { Criteria } from "../components/criteria";
import { StepHero } from "../components/step-hero";
import { VarenikParamsImage, VarenikPrivetImage } from "../images";
import { loadData } from "../reducers/data.reducer";
import { SectionHeader } from "../components/section-header";
import { RootState } from "../store/root-reducer";
import { regionsSelector } from "../selectors/regions.selector";
import { suggestRegionLayout } from "../utils/layout";
import { criteriaSelector } from "../selectors/criteria.selector";
import {
  changeCountryPick,
  changeCriterionPick,
  clearCountryPicks,
  clearCriteriaPicks,
} from "../reducers/pick.reducer";
import { Country, Criterion } from "../domain";
import { SteeperPreview, StepPreview } from "../components/stepper-preview";
import { logPageViewEvent } from "../utils/gtag";

const flagHero: SxProps<Theme> = ({ palette }) => ({
  bgcolor: palette.flag.blue,
  color: palette.text.white,
});

const selector = (state: RootState) => ({
  regions: regionsSelector(state),
  criteria: criteriaSelector(state),
  selectedCountries: state.pick.countries,
  selectedCriteria: state.pick.criteria,
});

const Main: React.FC = () => {
  const dispatch = useDispatch();
  const { regions, criteria, selectedCriteria, selectedCountries } =
    useSelector(selector);

  useEffect(() => {
    logPageViewEvent({
      page_title: "Varenik.help - Main",
    });
  }, []);

  useEffect(() => {
    dispatch(loadData());
  }, [dispatch]);

  const handleClearCountries = useCallback(() => {
    dispatch(clearCountryPicks());
  }, [dispatch]);
  const handleCountryChange = useCallback(
    (country: Country["code"], checked: boolean) => {
      dispatch(changeCountryPick({ countries: [country], selected: checked }));
    },
    [dispatch]
  );

  const handleAddAllCriteria = useCallback(() => {
    const codes = criteria.map(({ code }) => code);
    dispatch(changeCriterionPick({ criteria: codes, selected: true }));
  }, [criteria, dispatch]);
  const handleClearCriteria = useCallback(() => {
    dispatch(clearCriteriaPicks());
  }, [dispatch]);
  const handleCriterionChange = useCallback(
    (criterion: Criterion["code"], checked: boolean) => {
      dispatch(
        changeCriterionPick({ criteria: [criterion], selected: checked })
      );
    },
    [dispatch]
  );

  const regionsLayout = suggestRegionLayout(regions);

  return (
    <>
      <Container>
        <Grid container spacing={12} px={{ xl: 5 }}>
          <Grid item xs={12} md={5}>
            <VarenikPrivetImage />
          </Grid>
          <Grid item xs={12} md={7}>
            <Typography variant="subtitle1">Давайте знакомиться!</Typography>
            <Typography variant="subtitle1" mt={1}>
              Я – Varenik! Меня придумали и создали волонтеры команды Rubikus! С
              начала войны более 60 000 человек с помощью Rubikus выехали из
              Украины и нашли приют в разных странах Европы и мира.
            </Typography>
            <Typography variant="subtitle1" mt={1}>
              За время работы у команды Rubikus накопилось огромное количество
              знаний о том, как принимают украинцев в каждой из стран. И теперь
              я готов поделиться этой нужной информацией с вами!
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Box mt={8} py={10} sx={flagHero}>
        <Container>
          <Box
            px={({ spacing }) => `calc(${(1 / 6) * 100}% - ${spacing(5 + 1)})`}
            pb={2}
            display={{ xs: "none", md: "block" }}
          >
            <SteeperPreview />
          </Box>
          <Grid container spacing={10}>
            <Grid item xs={12} md={4}>
              <Box
                display={{ xs: "flex", md: "none" }}
                justifyContent="center"
                pb={2}
              >
                <StepPreview index={1} />
              </Box>
              <StepHero
                name="Выбери параметры"
                description="Выбери из списка интересующие тебя страны и параметры, по которым хочешь их сравнить."
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                display={{ xs: "flex", md: "none" }}
                justifyContent="center"
                pb={2}
              >
                <StepPreview index={2} />
              </Box>
              <StepHero
                name="Получи результаты"
                description="С помощью сравнительной таблицы изучай, сравнивай, выбирай страны, в которых хотелось бы жить."
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                display={{ xs: "flex", md: "none" }}
                justifyContent="center"
                pb={2}
              >
                <StepPreview index={3} />
              </Box>
              <StepHero
                name="Сохрани и пользуйся"
                description="В один клик поделись своим выбором с другом — сохрани информацию или отправь на электронную почту."
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box mt={8}>
        <Container>
          {/* Countries */}
          <Grid
            container
            columnSpacing={5}
            rowSpacing={3}
            alignItems="baseline"
          >
            <Grid item>
              <SectionHeader title="Выбери Страны" />
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={handleClearCountries}>
                очистить все
              </Button>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} md={regionsLayout.europe.columns * 2} mt={5}>
              <CountriesRegion
                region="Европа"
                countries={regions.europe.countries}
                selected={selectedCountries}
                onChange={handleCountryChange}
              />
            </Grid>

            <Grid item xs={12} md={regionsLayout.other.columns * 2} mt={5}>
              <CountriesRegion
                region="Другие"
                countries={regions.other.countries}
                selected={selectedCountries}
                onChange={handleCountryChange}
              />
            </Grid>
          </Grid>

          {/* Criteria */}
          <Grid container mt={8} spacing={3}>
            <Grid item xs={12} md={9}>
              <Grid
                container
                columnSpacing={5}
                rowSpacing={3}
                alignItems="baseline"
              >
                <Grid item>
                  <SectionHeader title="Выбери Параметры" />
                </Grid>
                <Grid item>
                  <Grid container spacing={3}>
                    <Grid item>
                      <Button variant="outlined" onClick={handleAddAllCriteria}>
                        выбрать все
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="outlined" onClick={handleClearCriteria}>
                        очистить все
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Box mt={5}>
                <Criteria
                  criteria={criteria}
                  selected={selectedCriteria}
                  onChange={handleCriterionChange}
                />
              </Box>
            </Grid>

            <Grid item xs={0} md={3} alignSelf="end">
              <VarenikParamsImage />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box textAlign="center" mt={8}>
        <Button
          component={Link}
          to="/results"
          variant="contained"
          color="secondary"
          size="large"
        >
          Получить Результаты
        </Button>
      </Box>
    </>
  );
};

export { Main };
