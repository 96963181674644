import { StateFromReducersMapObject } from "@reduxjs/toolkit";

import { dataReducer } from "../reducers/data.reducer";
import { favoritesReducer } from "../reducers/favorites.reducer";
import { pickReducer } from "../reducers/pick.reducer";
import { selectedReducer } from "../reducers/selected.reducer";
import { shareReducer } from "../reducers/share.reducer";

const rootReducer = {
  data: dataReducer,
  pick: pickReducer,
  selected: selectedReducer,
  favorites: favoritesReducer,
  share: shareReducer,
};

export type RootState = StateFromReducersMapObject<typeof rootReducer>;

export { rootReducer };
