import { Components, Theme } from "@mui/material";

import { COLOR_WHITE } from "../colors";

const MuiButton: Components<Theme>["MuiButton"] = {
  defaultProps: {
    disableElevation: true,
  },
  styleOverrides: {
    sizeLarge: ({ theme: { typography } }) => ({
      ...typography.button,
      padding: "18px 40px",
    }),
    sizeMedium: ({ theme: { typography } }) => ({
      ...typography.body2,
      padding: "10px 40px",
    }),
    outlinedSecondary: {
      color: COLOR_WHITE,
      borderColor: COLOR_WHITE,
    },
  },
};

export { MuiButton };
