import { createSelector } from "reselect";
import { RootState } from "../store/root-reducer";
import { codeColumns, rows } from "../config/data.config";
import {
  pickedCountriesSelector,
  pickedCriteriaSelector,
} from "./pick.selector";
import { favoritesSelector } from "./favorites.selector";

const shortDataSelector = (state: RootState) => state.data.short;
const fullDataSelector = (state: RootState) => state.data.full;

export type FilteredTableDataContentType = {
  isLiked: boolean;
  isDisliked: boolean;
  values: string[];
};

export type FilteredTableDataType = {
  header: string[];
  content: FilteredTableDataContentType[];
  config: boolean[];
  codes: string[];
};

const filteredTableDataSelector: (
  state: RootState
) => FilteredTableDataType | null = createSelector(
  [
    shortDataSelector,
    pickedCountriesSelector,
    pickedCriteriaSelector,
    favoritesSelector,
  ],
  (data, countries, criteria, favorites) => {
    if (data == null) {
      return null;
    }

    const header = data[rows.headerRow] as string[];
    const codes = data[rows.columnCodeRow] as string[];

    if (countries.length === 0 || criteria.length === 0) {
      return { header, content: [], config: [], codes: [] };
    }

    const content = (data.slice(rows.firstDataRow) as string[][]).filter(
      (row) => countries.includes(row[codeColumns.countryCode])
    );
    const config = codes.map((code) => criteria.includes(code));

    const likedRows = favorites.liked.reduce<FilteredTableDataContentType[]>(
      (result, countryCode) => {
        const row = content.find(
          (row) => row[codeColumns.countryCode] === countryCode
        );
        if (row != null) {
          result.push({
            isLiked: true,
            isDisliked: false,
            values: row,
          });
        }
        return result;
      },
      []
    );

    const dislikedRows = favorites.disliked.reduce<
      FilteredTableDataContentType[]
    >((result, countryCode) => {
      const row = content.find(
        (row) => row[codeColumns.countryCode] === countryCode
      );
      if (row != null) {
        result.push({
          isLiked: false,
          isDisliked: true,
          values: row,
        });
      }
      return result;
    }, []);

    const restOfRows = content
      .filter(
        (row) =>
          !favorites.liked.includes(row[codeColumns.countryCode]) &&
          !favorites.disliked.includes(row[codeColumns.countryCode])
      )
      .map<FilteredTableDataContentType>((row) => ({
        isLiked: false,
        isDisliked: false,
        values: row,
      }));

    return {
      header,
      content: [...likedRows, ...restOfRows, ...dislikedRows],
      config,
      codes,
    };
  }
);

export { shortDataSelector, fullDataSelector, filteredTableDataSelector };
