import { createSelector } from "reselect";
import { codeColumns, rows } from "../config/data.config";
import { RootState } from "../store/root-reducer";
import { fullDataSelector } from "./data.selector";

const selectedSelector = (state: RootState) => state.selected;

type SelectedDetails = {
  value: string;
  country: string;
  criteria: string;
};

const selectedDataSelector: (state: RootState) => SelectedDetails | null =
  createSelector([fullDataSelector, selectedSelector], (data, selected) => {
    if (data == null || selected == null) {
      return null;
    }

    const header = data[rows.headerRow];
    const codes = data[rows.columnCodeRow];
    const row = data.find(
      (row) => row[codeColumns.countryCode] === selected.countryCode
    );
    const criteriaIndex = codes.indexOf(selected.criteriaCode);

    if (row == null || criteriaIndex === -1) {
      return null;
    }

    return {
      value: row[criteriaIndex],
      country: row[codeColumns.countryName],
      criteria: header[criteriaIndex],
    };
  });

export { selectedDataSelector, selectedSelector };
