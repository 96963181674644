import React from "react";
import { Box, Typography } from "@mui/material";

type Props = {
  name: string;
  description: string;
};

const StepHero: React.FC<Props> = ({ name, description }) => {
  return (
    <Box textAlign="center">
      <Typography variant="h1">{name}</Typography>
      <Typography mt={2} variant="subtitle1">
        {description}
      </Typography>
    </Box>
  );
};

export { StepHero };
