import React, { useCallback } from "react";
import {
  CheckboxProps,
  Chip,
  ChipProps,
  FormControlLabelProps,
} from "@mui/material";
import { CheckboxField } from "../checkbox-field";

type Props = Pick<FormControlLabelProps, "label"> & {
  value: NonNullable<CheckboxProps["value"]>;
  checked: NonNullable<CheckboxProps["checked"]>;
  onClick: (value: NonNullable<CheckboxProps["value"]>) => void;
};

const ChipCheckbox: React.FC<Props> = ({ label, value, checked, onClick }) => {
  const handleClick: NonNullable<ChipProps["onClick"]> = useCallback(
    (event) => {
      event.preventDefault();
      onClick(value);
    },
    [onClick, value]
  );

  return (
    <Chip
      onClick={handleClick}
      variant="outlined"
      color={checked ? "primary" : undefined}
      label={<CheckboxField label={label} checked={checked} value={value} />}
      tabIndex={-1}
    />
  );
};

export { ChipCheckbox };
