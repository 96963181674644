import { Components, Theme } from "@mui/material";

const MuiLink: Components<Theme>["MuiLink"] = {
  styleOverrides: {
    underlineAlways: ({ theme: { spacing } }) => ({
      textUnderlineOffset: spacing(0.5),
    }),
  },
};

export { MuiLink };
