import { createAction, createReducer } from "@reduxjs/toolkit";

interface SelectedState {
  countryCode: string;
  criteriaCode: string;
}

const NAMESPACE = "selected";

const setSelected = createAction<SelectedState>(`${NAMESPACE}/set`);
const clearSelected = createAction(`${NAMESPACE}/clear`);

const selectedReducer = createReducer<SelectedState | null>(null, (builder) => {
  builder
    .addCase(setSelected, (state, action) => {
      return {
        ...action.payload,
      };
    })
    .addCase(clearSelected, (state, action) => {
      return null;
    });
});

export { selectedReducer, setSelected, clearSelected };
