import {
  Avatar,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
} from "@mui/material";

const StepPreview = ({ index }: { index: number }) => (
  <Avatar
    sx={({ spacing }) => ({
      bgcolor: "flag.yellow",
      color: "flag.blue",
      width: spacing(5),
      height: spacing(5),
    })}
  >
    {index}
  </Avatar>
);

const SteeperPreview = () => (
  <Stepper
    nonLinear
    connector={
      <StepConnector
        sx={{
          [`.${stepConnectorClasses.line}`]: { borderColor: "flag.yellow" },
        }}
      />
    }
  >
    {[1, 2, 3].map((index) => (
      <Step key={index} active>
        <StepLabel icon={<StepPreview index={index} />} />
      </Step>
    ))}
  </Stepper>
);

export { SteeperPreview, StepPreview };
