import { createSelector } from "reselect";

import { RootState } from "../store/root-reducer";
import { ExportResultsRequest } from "../domain";
import {
  pickedCountriesSelector,
  pickedCriteriaSelector,
} from "./pick.selector";
import { favoritesSelector } from "./favorites.selector";

const exportResultsRequestSelector: (state: RootState) => ExportResultsRequest =
  createSelector(
    [pickedCountriesSelector, pickedCriteriaSelector, favoritesSelector],
    (countries, criteria, favorites) => {
      return {
        countries: countries.join(","),
        attributes: criteria.join(","),
        likes: favorites.liked.join(","),
        dislikes: favorites.disliked.join(","),
      };
    }
  );

export { exportResultsRequestSelector };
