import React, { useCallback, useMemo } from "react";
import { Box, CheckboxProps, Typography } from "@mui/material";
import { Country } from "../../domain";
import { CheckboxField } from "../../inputs/checkbox-field";

type Props = {
  region: string;
  countries: Country[];
  selected: Country["code"][];
  onChange: (country: Country["code"], checked: boolean) => void;
};

const CountriesRegion: React.FC<Props> = ({
  region,
  countries,
  selected,
  onChange,
}) => {
  const checked = useMemo(() => new Set(selected), [selected]);

  const handleChange: CheckboxProps["onChange"] = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const country = event.target.value;
      onChange(country, !checked.has(country));
    },
    [checked, onChange]
  );

  return (
    <>
      <Typography variant="h2" textTransform="uppercase">
        {region}
      </Typography>
      <Box
        mt={3}
        sx={{ columnCount: "auto", columnWidth: ({ spacing }) => spacing(20) }}
      >
        {countries.map((country) => {
          const disabled = !country.acceptsRefugees;
          return (
            <Box key={country.code} maxWidth="100%">
              <CheckboxField
                label={country.name}
                noWrap
                checked={checked.has(country.code)}
                onChange={handleChange}
                value={country.code}
                disabled={disabled}
                title={disabled ? "Не принимает беженцев" : undefined}
              />
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export { CountriesRegion };
