import React, { PropsWithChildren } from "react";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { VarenikSuccess } from "../../images";
import { COLOR_GREY_LIGHT } from "../../theme/colors";

type Props = {
  image: React.ReactNode;
  title: string;
  description: string;
  disabled?: boolean;
  success?: React.ReactNode;
};

const ShareCard: React.FC<PropsWithChildren<Props>> = ({
  image,
  title,
  description,
  success,
  disabled,
  children,
}) => (
  <Card
    variant="outlined"
    sx={{
      display: "flex",
      flexDirection: "column",
      bgcolor: disabled
        ? COLOR_GREY_LIGHT
        : success
        ? "primary.light"
        : undefined,
    }}
  >
    <CardContent>
      <Typography
        variant="body2"
        component="h2"
        textAlign="center"
        color={disabled ? "text.disabled" : undefined}
      >
        {success ? "Готово!" : title}
      </Typography>
    </CardContent>
    <CardContent>
      <Stack direction={{ xs: "column", md: "row" }} spacing={3}>
        <Box mx="auto">{success ? <VarenikSuccess /> : image}</Box>
        <Typography
          variant="body5"
          mt={1}
          color={disabled ? "text.disabled" : undefined}
        >
          {success ?? description}
        </Typography>
      </Stack>
    </CardContent>
    {success === undefined && (
      <CardContent
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
        }}
      >
        {children}
      </CardContent>
    )}
  </Card>
);

export { ShareCard };
