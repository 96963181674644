import { createSelector } from "reselect";

import { RootState } from "../store/root-reducer";
import { CodesRow, Country, DataRow, RegionCountries } from "../domain";
import { codeColumns, rows } from "../config/data.config";

const shortDataSelector = (state: RootState) => state.data.short;

function makeCountry(row: DataRow, codesRow: CodesRow): Country {
  const countryCodeIndex = codeColumns.countryCode;
  const countryNameIndex = codeColumns.countryName;
  const acceptsRefugeesIndex = codeColumns.acceptsRefugees;

  return {
    code: row[countryCodeIndex] as string,
    name: row[countryNameIndex] as string,
    acceptsRefugees: row[acceptsRefugeesIndex] as boolean,
  };
}

const regionsSelector: (state: RootState) => RegionCountries = createSelector(
  [shortDataSelector],
  (data) => {
    if (data === null) {
      return {
        europe: { countries: [] },
        other: { countries: [] },
      };
    }

    const codesRow = data[rows.columnCodeRow] as CodesRow;
    const isEuropeIndex = codeColumns.isEurope;
    const countryCodeIndex = codeColumns.countryCode;

    const dataRows = data.slice(rows.firstDataRow);
    const europeCountries: Country[] = dataRows
      .filter(
        (row) => row[countryCodeIndex] !== "" && row[isEuropeIndex] === true
      )
      .map((row) => makeCountry(row, codesRow));
    const otherCountries: Country[] = dataRows
      .filter(
        (row) => row[countryCodeIndex] !== "" && row[isEuropeIndex] !== true
      )
      .map((row) => makeCountry(row, codesRow));

    return {
      europe: {
        countries: europeCountries,
      },
      other: {
        countries: otherCountries,
      },
    };
  }
);

export { regionsSelector };
