import { Components, Theme } from "@mui/material";

const MuiToolbar: Components<Theme>["MuiToolbar"] = {
  styleOverrides: {
    root: ({ theme: { spacing } }) => ({
      minHeight: spacing(6),
    }),
  },
};

export { MuiToolbar };
