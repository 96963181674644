import { createAction, createReducer } from "@reduxjs/toolkit";

interface DataState {
  updateDate: Date | null;
  short: (string | boolean)[][] | null;
  full: string[][] | null;
}

const NAMESPACE = "data";

const loadData = createAction(`${NAMESPACE}/loadData`);
const setData = createAction<DataState>(`${NAMESPACE}/set`);

const initialState = { updateDate: null, short: null, full: null } as DataState;

const dataReducer = createReducer(initialState, (builder) => {
  builder.addCase(setData, (state, action) => {
    return {
      ...action.payload,
    };
  });
});

export { dataReducer, loadData, setData };
